@import url('https://fonts.googleapis.com/css2?family=Pathway+Extreme:wght@100&display=swap');

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  font-family: 'Pathway Extreme', sans-serif;
}


.contact-form form{
  display:flex;
  flex-direction: column;
  align-items: center;
}


.contact-form{
  display:flex;
  flex-direction: column;
  align-items: center;
  
}
.contact-form input{
  padding:10px 5px 10px 5px;
  margin-bottom: 20px;
  width:30vw;
}
.contact-form textarea{
  padding:10px 5px 10px 5px;
  margin-bottom: 20px;
  width:30vw;
  height:200px;
}

.contact-form button{
  padding:10px 5px 10px 5px;
  margin:40px;
  width:30vw;
  background: #21325e;
  color: #ffffff;
  border-radius: 5px;
}

#message{
  width:30vw;
  height:200px;
  padding:10px 5px 10px 5px;
}

li{
  list-style-type: none;
}

@media only screen and (max-width: 600px) {
  .contact-form input{
    padding:10px 5px 10px 5px;
    margin-bottom: 20px;
    width:80vw;
  }
  .contact-form textarea{
    padding:10px 5px 10px 5px;
    margin-bottom: 20px;
    width:80vw;
    height:200px;
  }
  
  .contact-form button{
    padding:10px 5px 10px 5px;
    margin:40px;
    width:80vw;
    background: #21325e;
    color: #ffffff;
    border-radius: 5px;
  }
  
  
  #message{
    width:80vw;
    height:200px;
    padding:10px 5px 10px 5px;
  }
}

